/* Grid layout */
.parent {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid-container-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns on desktop */
    /* grid-gap: 20px; */
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {
    .grid-container-home {
        grid-template-columns: repeat(1, 1fr); /* 1 column on mobile */
        height: 100%;
        width: 100%;
    }
}

/* Card styling */
.card-menu {
    display: flex;
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid #ddd;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    flex: 1; /* Allow flex grow */
    /* max-width: 30%; */
    margin: 5px;
    justify-content: center;
    align-items: center; /* Align text vertically */
    flex-direction: column;
}
