@keyframes flashGreen {
  0%, 100% { box-shadow: 0 0 10px green; }
  50% { box-shadow: 0 0 20px green; }
}

@keyframes flashRed {
  0%, 100% { box-shadow: 0 0 10px red; }
  50% { box-shadow: 0 0 20px red; }
}
  
.flash-green {
  animation: flashGreen 1s;
}
  
.flash-red {
  animation: flashRed 1s;
}

.selected-card {
  /* box-shadow: 0 0 10px gold !important; */
  border: 3px solid #4a148c44 !important;
  transform: scale(0.95);
}

.successful-card {
  animation: flashGreen 1s;
}

.failed-card {
  animation: flashRed 1s;
}

.card {
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  flex: 1; /* Allow flex grow */
  /* max-width: 30%; */
  /* margin: 5px; */
}

/* Ensures hover logic does not affect mobile version.*/
@media (min-width: 768px) {
  .card:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  }
}

.card img {
  width: 100%;
  height: auto;
}

.nofront {
  display: none;
}

.noback {
  display: none;
}

.svg-container {
  /* Makes the container flexible */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Adjust as needed */
  width: 100%; /* Adjust as needed */
}

.card-container {
  /* Makes the container flexible */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Adjust as needed */
  width: 100%; /* Adjust as needed */
}

.flipped {
  transform: rotateY(180deg);
}

/* .card-container {
  perspective: 1000px;
} */

.card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

/* .card.flipped .card-inner {
  transform: rotateY(180deg);
} */

/* .front, .back {
  backface-visibility: hidden;
} */

/* .back {
  transform: rotateY(180deg);
} */
