

*, *::before, *::after {
    box-sizing: border-box; /* Include padding and border in the element's width and height */
  }


/* desktop version */
@media (min-width: 768px) {
    .setgame {
        /* padding: 20px; */
        display: flex;
        flex-direction: row;
        /* height: 100%; */
        width: 100%;
        /* make sure the height of this container doesn't overflow the screen */
    }

    .info {
        font-size: 16px;
        color: #333;
        padding: 10px;
        flex: 0 0 25%;
    }

    .game-grid-container {
        flex: 0 1 50%; 
        padding-top: 20px;
        max-height: 100%;
    }

    .fill-space {
        flex: 0 0 25%;
    }

    .game-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 4 columns */
        grid-gap: 10px;
        height: fit-content;
        /* height: 100%; */
    }
}

/* mobile version */
@media (max-width: 768px) {
    .setgame {
        /* padding: 20px; */
        display: flex;
        flex-direction: column;
        /* width: 100%; */
        /* height: 100%; */
    }
    
    .info {
        font-size: 16px;
        color: #333;
        padding: 10px;
        flex: 0 0 10%; /* 20% of the container */
    }
    .game-grid-container {
        flex: 0 0 50%; /* 80% of the container */
        padding-top: 20px;
    }

    .fill-space {
        flex: 0 0 20%; /* 20% of the container */
    }

    .game-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 4 columns */
        grid-gap: 10px;
        height: 100%;
    }
}

.deck {
    font-weight: bold;
    font-size: 24px;
    color: #333;
}

.success_note {
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
}



.button-snazzy {
    background-color: #5c6bc0; /* A snazzy purple */
    color: white;
    padding: 10px 20px;
    font-family: 'Arial', sans-serif;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    outline: none;
}
  
.button-snazzy:hover {
background-color: #7e8cfa; /* A lighter purple for hover */
transform: scale(1.05);
outline: none;
}

.info-item {
font-size: 16px;
margin-bottom: 10px;
}
