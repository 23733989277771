

*, *::before, *::after {
  box-sizing: border-box; /* Include padding and border in the element's width and height */
}


/* desktop version */
@media (min-width: 768px) {
  .setgame {
      /* padding: 20px; */
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      /* make sure the height of this container doesn't overflow the screen */
  }

  .info {
      font-size: 16px;
      color: #333;
      padding: 10px;
      flex: 0 0 25%; /* 20% of the container */
  }

  .game-grid-container {
      flex: 0 1 50%; /* 80% of the container */
      padding-top: 20px;
      max-height: 100%;
  }

  .fill-space {
      flex: 0 0 25%; /* 20% of the container */
  }

  .game-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 4 columns */
      grid-gap: 5px;
      height: 100%;
  }
}

/* mobile version */
@media (max-width: 768px) {
  .setgame {
      padding: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
  }
  
  .info {
      font-size: 16px;
      color: #333;
      padding: 10px;
      flex: 0 0 20%; /* 20% of the container */
  }
  .game-grid-container {
      flex: 0 0 60%; /* 80% of the container */
      padding-top: 20px;
  }

  .fill-space {
      flex: 0 0 20%; /* 20% of the container */
  }

  .game-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 4 columns */
      grid-gap: 10px;
      height: 100%;
  }
}

.deck {
  font-weight: bold;
  font-size: 24px;
  color: #333;
}

.success_note {
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
}




